.block {
  position: relative;
  &--hpbanner {
    .large-header {
      margin: 0 0 2px;
      font-size: 50px;
      font-weight: bold;

      @include respond-below(md) {
        font-size: 42px;
      }

      @include respond-below(sm) {
        font-size: 38px;
      }
    }
    .medium-header {
      margin-bottom: 12px;
      font-size: 28px;

      @include respond-below(sm) {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }
  }
  &--partner {
    .item-carousel {
      position: relative;
      height: 120px;
      &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        max-width: 80%;
        max-height: 120px;
        transform: translate(-50%, -50%);
      }
    }
    .slick {
      &-slider{
        position: relative;
        width: 100%;
      }
      &-arrow{
        bottom: -50px;
      }
      
    }
  }
}
