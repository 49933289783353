.member {
  padding: 15px;
  margin-right: 150px;
  .picture {
    width: 90%;
    margin-right: 15px;
    img {
      width: 100%;
    }
  }
}
.slick-next {
  right: auto;
  left: 50%;
  &::before {
    transform: rotate(-180deg);
  }
}
.slick-prev {
  right: 50%;
  left: auto;
}
.slick-arrow {
  &::before {
    display: block;
    width: 10px;
    height: 25px;
    content: ' ';
    background: transparent url('~@assets/images/icons/slider-left.png') 0% 0%
      no-repeat padding-box;
    background-size: 15px 25px;
  }

  top: auto;
  bottom: -25px;
  margin: 10px;
}
