$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "/fonts/icomoon" !default;

$ico-icon-1: "\e901";
$ico-icon-2: "\e908";
$ico-icon-3: "\e909";
$ico-icon-4: "\e90a";
$ico-icon-5: "\e90b";
$ico-icon-6: "\e90c";
$ico-connecte: "\e900";
$ico-medal: "\e907";
$ico-organise: "\e902";
$ico-protege: "\e903";
$ico-search: "\e904";
$ico-suitcase: "\e905";
$ico-win: "\e906";
