@font-face {
    font-family: 'Gotham';
    src: local('Gotham Medium'), local('Gotham-Medium'),
        url('/fonts/gotham-font/Gotham-Medium.woff2') format('woff2'),
        url('/fonts/gotham-font/Gotham-Medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham Light'), local('Gotham-Light'),
        url('/fonts/gotham-font/Gotham-Light.woff2') format('woff2'),
        url('/fonts/gotham-font/Gotham-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

