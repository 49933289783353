@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  font-style: normal;
  font-weight: normal;
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vu5d2b");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vu5d2b#iefix") format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vu5d2b") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vu5d2b") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vu5d2b##{$icomoon-font-family}") format("svg");
  font-display: block;
}

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-icon-1 {
  &::before {
    color: #fff;
    content: $ico-icon-1;
  }
}
.ico-icon-2 {
  &::before {
    color: #fff;
    content: $ico-icon-2;
  }
}
.ico-icon-3 {
  &::before {
    color: #fff;
    content: $ico-icon-3;
  }
}
.ico-icon-4 {
  &::before {
    color: #fff;
    content: $ico-icon-4;
  }
}
.ico-icon-5 {
  &::before {
    color: #fff;
    content: $ico-icon-5;
  }
}
.ico-icon-6 {
  &::before {
    color: #fff;
    content: $ico-icon-6;
  }
}
.ico-connecte {
  &::before {
    content: $ico-connecte;
  }
}
.ico-medal {
  &::before {
    content: $ico-medal;
  }
}
.ico-organise {
  &::before {
    content: $ico-organise;
  }
}
.ico-protege {
  &::before {
    content: $ico-protege;
  }
}
.ico-search {
  &::before {
    content: $ico-search;
  }
}
.ico-suitcase {
  &::before {
    content: $ico-suitcase;
  }
}
.ico-win {
  &::before {
    content: $ico-win;
  }
}
