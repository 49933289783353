// theme colors
$color-theme-white: #fff;
$color-theme-purple: #761d75;
$color-theme-green: #8eb637;
$color-theme-blue: #00a3df;
$color-theme-pink: #db0068;
$color-theme-orange: #fca026;
$color-theme-orange-dark: #e4431b;
$color-theme-red: #cb0522;

// CONTENT
$color-body-bg: #000;
$color-text: $color-theme-white;
$color-heading-text: $color-theme-white;

// LINKS
$color-link-text: #39a275;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;
